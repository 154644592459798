import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from './App';
import { configureStore } from './redux/Store';
import './data';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import Spinner from './views/spinner/Spinner';
import { lazy } from 'react';
import { apiGetSession } from './apis';

const initalization = async () => {
  try {
    /* The code `let result = await apiGetSession();` is making an asynchronous API call to retrieve
    the session data. The `await` keyword is used to wait for the API call to complete and return a
    response. */
    let result = await apiGetSession();
    localStorage.setItem('session', result.init);

    const App = lazy(() => import('./App'));
    /* The code `ReactDOM.render()` is responsible for rendering the React application to the DOM
   (Document Object Model). */
    ReactDOM.render(
      <Provider store={configureStore()}>
        <Suspense fallback={<Spinner />}>
          <HashRouter>
            <App />
          </HashRouter>
        </Suspense>
      </Provider>,
      document.getElementById('root')
    );
  } catch (error) {
    //console.log("Initialization", error);
  }
};

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
initalization();
